/* global $, ga, JSM */
$().ready(function(){
	setupSlideMenu();
	runBanners();
	$("form").each(function(){
		$(this).addClass("infield-active").validate();
		$(".infield").inFieldLabels();
	});
	if ($("html").hasClass("webgl")){
		if ($("#model").length > 0) LoadOnline3DModels();
	}
	setupSliders();
	$("a.get-quote").on("click touch",function(e){
		var $this = $(this);
		if (window.ga) {
			if ($this.target=="_blank") {
				window.ga("send", "event", "Product", "Get Quote", $this.data("product"));
				return;
			}
			e.preventDefault();
			var navigated =false;
			var navigate = function(){
				if (!navigated) {
					navigated = true;
					document.location = $this.attr("href");
				}
			};
			// Creates a timeout to navigate after one second.
			setTimeout(navigate, 1000);
			window.ga("send", "event", "Product", "Get Quote", $this.data("product"), {"hitCallback": navigate});
		}
	});
});

export function setupSlideMenu(){
	$("#mobile-menu, .slide-menu h3").click(function(){
		$("body").toggleClass("slide-menu-active");
	});
	$(".slide-menu").on("click",".expand, > ul > li:first",function(e) {
		e.stopPropagation();
		$(this).closest("li").toggleClass("expand-active");
	});

	$(".slide-menu li").each(function(index, element) {
		var $el = $(element);
		if ($el.find("ul").length > 0) {
			var expander = $("<span class=\"expand\"><i class=\"nav-arrow\"></i></span>");
			$el.append(expander);
			expander.click(function(e) {
				e.stopPropagation();
				$el.toggleClass("expand-active");
			});
		}
	});
	$("#drop-menu").click(function(){
		$(this).parent().toggleClass("expand-active");
	});
	$(".footer-products").on("click",".expand",function(e) {
		e.stopPropagation();
		$(this).closest("li").toggleClass("expand-active");
	});
	$(".footer-products li").each(function(index, element) {
		var $el = $(element);
		if ($el.find("ul").length > 0) {
			var expander = $("<span class=\"expand\"><i class=\"nav-arrow\"></i></span>");
			$el.append(expander);
			expander.click(function(e) {
				e.stopPropagation();
				$el.toggleClass("expand-active");
			});
		}
	});
}

var rotateInterval;
export function runBanners(){
	if( $(".banners .banner").length > 1){
		rotateInterval = setInterval(function(){swapBanner();}, 7000);
		bannerNav();
		$(".banners-home .banner-navigation").show();
	}
}
export function swapBanner(){
	var $oldb = $(".banners-home .banner.active");
	var $newb = $(".banners-home .banner.active").next();
	var $lastb = $(".banners-home .banner:last-of-type");
	var $name = null;

	if (! $lastb.hasClass("active")){
		$oldb.removeClass("active");
		$newb.addClass("active");
		/* banner nav */
		$(".banners-home .banner-nav-item").removeClass("active");
		$name = $(".banners-home .banner.active .banner-name").text();
		$(".banners-home .banner-nav-item .banner-name").filter(function(){ return $(this).text() === $name;}).closest(".banner-nav-item").addClass("active");
	} else {
		$oldb.removeClass("active");
		$(".banners-home .banner:first-of-type").addClass("active");
		/* banner nav */
		$(".banners-home .banner-nav-item").removeClass("active");
		$name = $(".banners-home .banner.active .banner-name").text();
		$(".banners-home .banner-nav-item .banner-name").filter(function(){ return $(this).text() === $name;}).closest(".banner-nav-item").addClass("active");
	}
}
export function bannerNav(){
	$(".banners-home .banner-nav-item").click(function(){
		$(".banners-home .banner-nav-item").removeClass("active");
		$(".banners-home .banner").removeClass("active");

		$(this).addClass("active");
		var $name = $(".banners-home .banner-nav-item.active .banner-name").text();
		$(".banners-home .banner-content .banner-name").filter(function(){ return $(this).text() === $name;}).closest(".banner.banner-image").addClass("active");

		clearInterval(rotateInterval);
		rotateInterval = setInterval(function(){swapBanner();}, 7000);
	});
}

export function checkSliderSize($sliders){
	$sliders.each(function() {
		var $content =$(this).find(".scroller");
		if ($content.scrollLeft() ==0) {
			$(this).find(".prev").hide();
		} else {
			$(this).find(".prev").show();
		}
		if ($content.scrollLeft() + $content.width() >= $content[0].scrollWidth) {
			$(this).find(".next").hide();
		} else {
			$(this).find(".next").show();
		}
	});
}

export function setupSliders() {
	var $sliders = $(".scroll-wrap");
	var scrolldistance = 150;
	$sliders.each(function(index, element) {
		var $slider = $(element);
		var $scroller = $slider.find(".scroller");
		var $next = $slider.find(".next");
		var $prev = $slider.find(".prev");
		$prev.on("click touch",function(){
			if ($scroller.scrollLeft() > 0){
				var newPos = $scroller.scrollLeft()-scrolldistance;
				$scroller.stop().animate({scrollLeft:newPos},500,"swing");
				$next.fadeIn();
			} else {
				$prev.fadeOut();
			}
		});
		$next.on("click touch",function(){
			if ($scroller.scrollLeft() + $scroller.width() < $scroller[0].scrollWidth){
				var newPos = $scroller.scrollLeft()+scrolldistance;
				$scroller.stop().animate({scrollLeft:newPos},500,"swing");
				$prev.fadeIn();
			} else {
				$next.fadeOut();
			}
		});
	});
	$(window).resize(function(){checkSliderSize($sliders);});
	checkSliderSize($sliders);
}

export function LoadOnline3DModels(){
	InitialiseOnline3dModels();
}

export function InitialiseOnline3dModels() {
	var supported = JSM.IsWebGLEnabled () && JSM.IsFileApiEnabled ();
	if (!supported) return;

	function Error (viewerElement, message)
	{
		var context = viewerElement.getContext ("2d");
		context.font = "12px Arial";
		context.fillText (message, 0, 12);
	}

	function LoadViewer (viewerElement)
	{
		var urls = viewerElement.getAttribute ("sourcefiles");
		if (urls === undefined || urls === null) {
			Error (viewerElement, "Invalid source files.");
			return;
		}

		var urlList = urls.split ("|");
		JSM.ConvertURLListToJsonData (urlList, {
			onError : function () {
				Error (viewerElement, "Conversion failed.");
				return;
			},
			onReady : function (fileNames, jsonData) {
				var viewerSettings = {
					cameraEyePosition : [6.0, -5.5, 4.0],
					cameraCenterPosition : [0.0, 0.0, 0.0],
					cameraUpVector : [0.0, 0.0, 1.0]
				};

				var viewer = new JSM.ThreeViewer ();
				$(viewerElement).data("viewer",viewer);
				if (!viewer.Start (viewerElement, viewerSettings)) {
					Error (viewerElement, "Internal error.");
					return;
				}

				var currentMeshIndex = 0;
				var environment = {
					onStart : function (/*taskCount, meshes*/) {
						viewer.EnableDraw (false);
					},
					onProgress : function (currentTask, meshes) {
						while (currentMeshIndex < meshes.length) {
							viewer.AddMesh (meshes[currentMeshIndex]);
							currentMeshIndex = currentMeshIndex + 1;
						}
					},
					onFinish : function (meshes) {
						if (meshes.length > 0) {
							viewer.AdjustClippingPlanes (50.0);
							viewer.FitInWindow ();
						}
						viewer.EnableDraw (true);
						viewer.Draw ();
						$(viewerElement).parent().find(".kms-loader").remove();
					}
				};

				var textureLoaded = function () {
					viewer.Draw ();
				};
				JSM.ConvertJSONDataToThreeMeshes (jsonData, textureLoaded, environment);
			}
		});
	}
	var $loader = $("#loader");
	$("#loader .load").show();
	$loader.one("click", function(){
		$("#loader .load").remove();
		$("#loader .kms-loader").show();
		var $canvas = $("#loader canvas");
		var product = $("#loader").data("title");
		if (typeof ga == "function"){
			ga("send", "event", "3d model", "load", product);
			ga("send", "pageview", "virtual-model-load");
		}
		function setcanvas(){
			$canvas.attr("height", $loader.height());
			$canvas.attr("width", $loader.width());
			if($canvas.data("viewer"))
				$canvas.data("viewer").Resize();
		}
		$(window).on("resize", setcanvas);
		setcanvas();

		LoadViewer($canvas.get(0));
	});
}